<template>
	<div id="similar-people">
		<user-complex-filter header-title="Похожие" :close-after-submit="true" :show-filter="false" @filterSubmit="filterSubmitListener" :show-matched-option="false" :on-init-load="true" ref="filter" :search-disabled="this.loading"></user-complex-filter>
		<div class="similar-people-content">
			<search-filter :show-error="true" :placeholderText="'Введите имя'" :items="similarPeople.matched_users" @filtered="filteredList" class="search-filter" :is-server-side="true" @onChange="onSearchChange" :search-disabled="this.loading"></search-filter>
			<div class="top-bar">
				<div class="friends-count">{{ similarPeople.count }} пользователей</div>
				<user-dropdown-filter :defaultSelected="orderBy" @onChange="onOrderByChange"></user-dropdown-filter>
			</div>
			<div class="users-list-wrapper__row">
				<DynamicScroller :items="similarPeople.matched_users" :min-item-size="200" class="scroller" page-mode @update="handleScroll" :emitUpdate="true" :buffer="1000">
					<template v-slot="{ item, index, active }">
						<DynamicScrollerItem :item="item" :active="active" :data-index="index" :size-dependencies="[item]">
							<user :user="item" :avatar="item.avatar_parsed" :send-request="true" :delete-friend="false" :delete-request="false" :accept-request="false"></user>
						</DynamicScrollerItem>
					</template>
				</DynamicScroller>
			</div>
		</div>
	</div>
</template>

<script>
import searchFilter from '../../../components/_shared/SearchFilter';
import userComplexFilter from "../../../components/_shared/UserComplexFilter";
import userDropdownFilter from "../../../components/_shared/UserFilterDropdown";
import {handleAWSImage} from "../../../_helpers/aws-handler";
import {questionsService, usersService} from "../../../_api";
import avatar from "../../../components/_shared/Avatar";
import PersonProgress from "../../../components/_shared/UserProgress";
import User from "../../../components/users/User";

export default {
	name: 'similar-people',
	data() {
		return {
			loadingChat: false,
			loadIndexThreshold: 10,
			loadIndex: 10,
			startIndex: 0,
			perPage: 10,
			page: 1,
			orderBy: 1,
			search_str: '',
			similarPeople: {
				count: 0,
				matched_users: [],
				matched_users_filtered: []
			},
			loading: false
		}
	},
	methods: {
		onOrderByChange(data) {
			console.log(data);
			this.orderBy = data;
			this.page = 1;
			this.loadIndex = 10;
			this.similarPeople.matched_users = [];
			this.$refs.filter.submitFilter();
		},
		onSearchChange(data) {
			this.search_str = data;
		},
		/*startChat(data) {
			this.loadingChat = true;
			usersService.getChatIdAPI(data).then((res) => {
				this.loadingChat = false;
				if (res) {
					this.$router.push({ path: '/messages', query: { t: res, ff: true } });
				}
			})
		},*/
		
		filterSubmitListener(data) {
			this.getUsers(data);
		},
		
		filteredList(data) {
			// console.log(data);
			// this.similarPeople.matched_users_filtered = data.arr;
			this.search_str = data.searchStr;
			this.page = 1;
			this.loadIndex = 10;
			this.similarPeople.matched_users = [];
			this.$refs.filter.submitFilter();
		},
		handleScroll(startIndex, endIndex) {
			console.log(endIndex  + ' / ' + this.loadIndex)
			if (endIndex === this.loadIndex) {
				this.loadIndex += this.loadIndexThreshold;
				this.$refs.filter.submitFilter();
			}
		},
		getUsers(data) {
			// this.similarPeople.matched_users = [];
			this.loading = true;
			let objToSend = {
				age_from: data.age_from,
				age_to: data.age_to,
				sex: (data.sex === 'any') ? null : data.sex,
				goals: data.goals,
				occupations: data.occupations,
				orientation: data.orientation_status.map(o => {return o.id}).toString(),
				family_status: data.family_status.map(o => {return o.id}).toString(),
				children: (data.children) ? data.children.id : null,
				education: (data.education) ? data.education.id : null,
				work_status: (data.work_status) ? data.work_status.id : null,
				location: data.location.place_id,
				perPage: this.perPage,
				page: this.page,
				name: this.search_str,
				orderBy: this.orderBy //1-BY_MATCHING; 2-BY_REG_DATE
			};
			usersService.getSimilarUsers(objToSend).then((res) => {
				// console.log(res);
				if (res) {
					//this.similarPeople.matched_users = [];
					
					this.similarPeople.count = res.count;
					// this.similarPeople.matched_users = res.matched_users;
					// this.page += 1;
					res.matched_users.forEach((obj) => {
						obj['avatar_parsed'] = null;
						obj['similar_count'] = obj.similar_result;
						obj['difference_count'] = obj.diff_answers;
						obj['not_answered_count'] = obj.not_answered_result;
						this.similarPeople.matched_users.push(obj);
						handleAWSImage(obj.avatar_135).then((res) => {
							obj['avatar_parsed'] = res;
						});
					});
					
					if (res.matched_users.length) {
						this.page += 1;
					}
				}
				setTimeout(() => {
					this.loading = false;
				}, 500)
			})
		},
		sendFriendRequest(data) {
			usersService.sendFriendRequestAPI({
				id: data.id
			}).then((res) => {
				// console.log(res);
				if (res) {
					data['is_friend_request'] = 1;
					this.$forceUpdate();
				}
			})
		},
		askQuestion(item) {
			this.$router.push({path: '/questions/personal-questions', query: {user_id: item.id}});
		},
		goToUserProfile(id) {
			this.$router.push({path: `/profile/${id}`});
		},
	},
	components: {
		User,
		PersonProgress,
		userComplexFilter,
		searchFilter,
		userDropdownFilter,
		avatar
	},
	created() {},
	mounted() {}
}
</script>

<style lang="less" scoped>
#similar-people {
	.similar-people-content {
		width: 100%;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
		border-radius: 1px;
		margin-bottom: 34px;
		background: #fff;
		
		.search-filter {
			border-top: 1px solid #e3e6eb;
			border-bottom: 1px solid #e3e6eb;
			margin-bottom: 16px;
		}
		
		.top-bar {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			margin-bottom: 32px;
			padding: 0 16px;
			
			.friends-count {
				font-size: 14px;
				line-height: 14px;
				color: #666666;
			}
			
			.sort-wrapper {
				font-size: 12px;
				line-height: 14px;
				color: #333333;
			}
		}
		
		.users-list-wrapper__row {
			/*width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;*/
			
			.user-item {
				width: 100%;
				display: flex;
				align-items: center;
				padding: 8px 25px;
				border-bottom: 1px solid #e3e6eb;
				
				.avatar-wrapper {
					margin-right: 25px;
					cursor: pointer;
				}
				
				.user-info-wrapper {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					width: 100%;
					
					.main-info {
						width: 68%;
						text-align: left;
						border-right: 1px solid #e3e6eb;
						margin-bottom: 9px;
						
						.user-fullname {
							font-weight: 500;
							font-size: 16px;
							line-height: 19px;
							color: #666666;
							margin-bottom: 8px;
							cursor: pointer;
						}
						
						.user-description {
							font-size: 14px;
							line-height: 16px;
							color: #999999;
							margin-bottom: 8px;
						}
						
						.person-progress {
							margin-bottom: 8px;
						}
						
						.answers-wrapper {
							span {
								font-size: 14px;
								line-height: 16px;
								color: #71809C;
								
								&.green-text-quantity {
									color: #AFD4AB;
									font-weight: bold;
								}
								
								&.red-text-quantity {
									color: #EDA2A4;
									font-weight: bold;
								}
								
								&.gray-text-quantity {
									color: #9B9B9B;
									font-weight: bold;
								}
							}
						}
					}
					
					.common-preferences {
						width: 25%;
						display: flex;
						flex-direction: column;
						margin-bottom: 9px;
						text-align: left;
						justify-content: center;
						
						span {
							font-size: 13px;
							line-height: 18px;
							color: #333333;
							margin-bottom: 14px;
							
							&:last-child {
								margin-bottom: 0px;
							}
						}
					}
					
					.actions-wrapper {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						/*justify-content: space-between;*/
						width: 100%;
						
						.def-btn {
							&:not(:last-child) {
								margin-right: 10px;
							}
							
							font-weight: 500;
							font-size: 10px;
							line-height: 12px;
							margin-bottom: 8px;
							width: 100%;
							max-width: 150px;
							
							&.disabled {
								background: rgba(0, 0, 0, 0.1) !important;
								color: #A9BAD4 !important;
								cursor: not-allowed;
							}
						}
					}
				}
			}
		}
	}
}
</style>

