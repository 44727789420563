<template>
    <div id="users">
        <transition name="fade">
            <router-view/>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'users',
        data () {
            return {
                test: null
            }
        },
        created() {
            if (this.$route.name === 'users') {
                this.$router.push('/users/social-networks')
            }
        }
    }
</script>

<style lang="less" scoped>
    #users {

    }
</style>

