<template>
    <div id="friends-answers">
        <search-filter :show-error="true" :placeholderText="'Поиск вопросов'" :items="arr" @filtered="filteredList" class="search-filter" :is-server-side="true" @onChange="onSearchChange" ref="search"></search-filter>

        <div class="content-wrapper" v-if="list">
            <DynamicScroller :items="list" :min-item-size="200" class="scroller" page-mode @update="handleScroll" :emitUpdate="true" :buffer="1000">
                <template v-slot="{ item, index, active }">
                    <DynamicScrollerItem :item="item" :active="active" :data-index="index" :size-dependencies="[item]">
                        <!--<question-for-feed :item="item" :list="list" :user-profile-view="true" :has-margin="20" :block-title="item.title" :show-item="true" :has-delete="false" :has-edit="false"></question-for-feed>-->

                        <QuestionPost v-if="item.type !== 101" :item="item" :list="list" :block-title="item.title" :has-delete="false" :has-edit="false" :topic-global-id="item.topic_global_id" :has-margin="20" :delete-after-submit="false" :is-feed="false" :read-only="false" :show-avatars="true" :show-title-avatar="true" :blurred-option="true"></QuestionPost>
                        <ImagePost :item="item" :list="list" :block-title="item.title" :has-margin="20" :isFeed="true" :show-avatars="true" v-else></ImagePost>
                    </DynamicScrollerItem>
                </template>
            </DynamicScroller>
        </div>
    </div>
</template>

<script>
    import searchFilter from "../../../components/_shared/SearchFilter";
    import {questionsService, usersService} from "../../../_api";
    import {handleAWSImage} from "../../../_helpers/aws-handler";
    import QuestionPost from "../../../components/feed/QuestionPostBody";
    import EventBus from "../../../_helpers/event-bus";
    import {parsePostBody} from "../../../_helpers/post-body-parser";
    import ImagePost from "../../../components/feed/ImagePostBody";


    export default {
        name: 'friends-answers',
        data () {
            return {
                arr: [],
                loading: true,
                loadIndexThreshold: 10,
                loadIndex: 10,
                page: 0,
                perPage: 10,
                list: [],
                search_str: ''
            }
        },
        components: {
            ImagePost,
            QuestionPost,
            searchFilter
        },
        computed: {
            user () {
                return this.$store.state.authentication.user;
            },
            hasAnswer() {
                return this.list.filter((o) => {return o.has_answer})
            }
        },
        methods: {
            handleScroll(startIndex, endIndex) {
                if (endIndex === this.loadIndex) {
                    this.loadIndex += this.loadIndexThreshold;
                    this.getUsersAnswers(false)
                }
            },
            getUsersAnswers(reload) {
                if (reload) {
                    this.list = [];
                    this.page = 0;
                }
                usersService.getUsersAnswers({
                    page: (!this.page) ? 1 : this.page + 1,
                    perPage: this.perPage,
                    query: this.search_str
                }).then(res => {
                    if (res.data) {
                        res.data.forEach((obj, index) => {
                            parsePostBody(obj).then(res_q => {
                                if (res_q.type === 101) {
                                    res_q['show_item'] = true;
                                    res_q['title'] = `${res_q.author.name} добавил фото`;
                                    res_q.photos.forEach((obj_c, index) => {
                                        obj_c['photo_parsed'] = null;
                                        obj_c['photo_parsed_min'] = null;
                                        handleAWSImage(obj_c.photo).then(response => {
                                            obj_c['photo_parsed'] = response;
                                            obj_c['photo_parsed_min'] = response;
                                            this.$forceUpdate();
                                        });
                                        this.$forceUpdate();
                                    });
	                                if (res_q.comments && res_q.comments.length) {
		                                if (res_q.comments[0].author) {
			                                res_q['title'] = `${res_q.comments[0].author.name} добавил комментарий к Вашему фото`;
			                                res_q.author['avatar_parsed'] = null;
			                                res_q.comments[0]['similar_answers_percent'] = null;
			                                res_q.comments[0]['diff_answers_percent'] = null;
			                                res_q.comments[0]['not_answered_percent'] = null;
			                                questionsService.getUserProgressBarAPI({
				                                user_ids: [res_q.comments[0].author.id]
			                                }).then(response => {
				                                if (response) {
					                                console.log(response);
					                                res_q.comments[0]['similar_answers_percent'] = response[0].similar_answers_percent;
					                                res_q.comments[0]['diff_answers_percent'] = response[0].diff_answers_percent;
					                                res_q.comments[0]['not_answered_percent'] = response[0].not_answered_percent;
					                                this.$forceUpdate();
				                                }
			                                });
			                                handleAWSImage(res_q.comments[0].author.avatar_36).then(res => {
				                                res_q.author['avatar_parsed'] = res;
				                                this.$forceUpdate();
			                                })
		                                } else {
			                                console.log(res_q);
			                                res_q['title'] = 'Удаленный пользователь';
			                                res_q.comments[0]['author'] = {
				                                is_deleted: 1,
				                                name: 'Удаленный пользователь'
			                                }
		                                }
		
		                                if (res_q.unique_count_authors > 1) {
			                                res_q['title'] = `${res_q.comments[0].author.name} и еще ${res_q.unique_count_authors - 1} ${((res_q.unique_count_authors - 1) === 1) ? 'пользователь' : 'пользователей'} добавили комментарии к Вашему фото`;
		                                }
	                                } else {
		                                if (res_q.author) {
			                                res_q.author['avatar_parsed'] = null;
			                                handleAWSImage(res_q.author.avatar_36).then(res => {
				                                res_q.author['avatar_parsed'] = res;
				                                this.$forceUpdate();
			                                })
		                                }
	                                }
                                } else {
                                    res_q['show_item'] = true;
                                    switch (res_q.type) {
                                        case 1:
                                            res_q['is_question_of_the_day'] = true;
                                            res_q['topic_global_id'] = 9;
                                            break;
                                        case 2:
                                            res_q['topic_global_id'] = 7;
                                            break;
                                        case 3:
                                            res_q['topic_global_id'] = 8;
                                            break;
                                        default:
                                            res_q['topic_global_id'] = 7;
                                            break;
                                    }
                                    res_q['title'] = `${res_q.user_name} ответил(а) на вопрос`;
                                    res_q['has_answer'] = res_q.answers.some(i => {
                                        if (i.my_checked) {
                                            res_q['selected'] = i;
                                        }
                                        return i.my_checked;
                                    });
                                    res_q.answers.forEach(i => {
                                        i['checked'] = i.my_checked;
                                    });
                                    res_q['has_answer_mode'] = res_q['has_answer'];
                                    res_q['avatar_parsed'] = null;
                                    handleAWSImage(res_q.user_avatar).then((img) => {
                                        res_q['avatar_parsed'] = img;
                                        this.$forceUpdate();
                                    });
                                }
                                this.list.push(res_q);
                            });
                        });

                        if (res.data.length) {
                            this.page += 1;
                        }
                    }

                })
            },
            onSearchChange(data) {
                this.search_str = data;
            },
            filteredList (data) {
                this.search_str = data.searchStr;
                if (this.search_str.length > 0) {
                    // this.list = data.arr;
                    this.getUsersAnswers(true);
                } else {
                    this.getUsersAnswers(true);
                }
            },
            searchQuestions(top_id, search_str) {
                questionsService.searchQuestionsAPI({
                    page: (!this.page) ? 1 : this.page + 1,
                    perPage: 10,
                    topic_id: top_id !== 9999999 ? top_id : null,
                    query: search_str,
                    type: null, // opened/closed/blank if all,
                    is_day: 0
                }).then((res) => {
                    //console.log(res);
                    if (res) {
                        res.data.forEach(obj => {

                            parsePostBody(obj).then(res_q => {
                                switch (res_q.type) {
                                    case 1:
                                        res_q['title'] = 'Вопрос дня';
                                        res_q['is_question_of_the_day'] = true;
                                        res_q['topic_global_id'] = 9;
                                        break;
                                    case 2:
                                        res_q['title'] = 'Основной вопрос';
                                        res_q['topic_global_id'] = 7;
                                        break;
                                    case 3:
                                        res_q['title'] = `Персональный вопрос от пользователя ${obj.author.name}`;
                                        res_q['topic_global_id'] = 8;
                                        break;
                                    default:
                                        res_q['title'] = 'Основной вопрос';
                                        res_q['topic_global_id'] = 7;
                                        break;
                                }

                                if (res_q.redirected === 1) {
                                    let users_name = '';
                                    res_q.who_asked.length === 1 ? users_name = 'Пользователь ' : 'Пользователи ';
                                    res_q.who_asked.forEach((p, index) => {
                                        if (res_q.who_asked.length === 1) {
                                            users_name += p.name;
                                        } else {
                                            users_name += `, ${p.name}`
                                        }
                                    });
                                    res_q['title'] = `${users_name} интересуется Вашим ответом на вопрос`;
                                }
                                if (res_q.type === 3 && res_q.who_asked.length) {
                                    let users_name = '';
                                    res_q.who_asked.length === 1 ? users_name = 'Пользователь ' : 'Пользователи ';
                                    res_q.who_asked.forEach((p, index) => {
                                        if (res_q.who_asked.length === 1) {
                                            users_name += p.name;
                                        } else {
                                            users_name += `, ${p.name}`
                                        }
                                    });
                                    //obj['title'] = `${users_name} интересуется Вашим ответом на вопрос`;
                                    res_q['title'] = `${users_name} интересуется Вашим ответом на персональный вопрос ${res_q.author.name}`;
                                }

                                res_q['has_answer'] = res_q.answers.some(i => {
                                    if (i.my_checked) {
                                        res_q['selected'] = i;
                                    }
                                    return i.my_checked;
                                });
                                res_q['has_answer_mode'] = res_q['has_answer'];
                                res_q['avatar_parsed'] = null;
                                handleAWSImage(res_q.user_avatar).then((img) => {
                                    obj['avatar_parsed'] = img;
                                    this.$forceUpdate();
                                });
                                this.list.push(res_q);
                            });

                        });
                        if (res.data.length) {
                            this.page += 1;
                        }
                    }
                    this.loading = false;
                    // console.log(this.listOfOpenQuestions);
                });
            },
            resetPage(id) {
                this.page = 0;
                this.list = [];
                this.loadIndex = this.loadIndexThreshold;
                // console.log(this.page);
            }
        },
        created() {},
        mounted() {
            EventBus.$on('loadMoreQuestions', (data) => {
                console.log('LISTENER ON')
                this.getUsersAnswers();
            });
            this.getUsersAnswers();
        },
        beforeDestroy() {
            // this.loadListenerOff()
            EventBus.$off('loadMoreQuestions');
        },
        watch: {}
    }
</script>

<style lang="less" scoped>
    #friends-answers {
        .search-filter {
            width: 100%;
            background: #fff;
            margin-bottom: 20px;
            border-radius: 5px;
        }
        .content-wrapper {
            width: 69.6%;
            padding-right: 20px;
        }
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {

    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {

    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #friends-answers {
            .content-wrapper {
                width: 100%;
                padding-right: 0;
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {

    }
</style>
