<template>
    <keep-alive>
        <div id="search-people">
            <keep-alive>
                <user-complex-filter :has-name-search-bar="true" header-title="Кого вы хотите найти?" :close-after-submit="false" :show-filter="filterOpen" @filterSubmit="filterSubmitListener" :show-matched-option="true" :on-init-load="false" ref="filter" :search-disabled="users.loading" :preloadData="preloadFilterData" :custom-matching-option="customMatchingOption" :has-invite-friends-option="false" :people-search-section-u-i="true"></user-complex-filter>
            </keep-alive>
            <div class="search-people-content" ref="search">
                <div class="top-bar">
                    <div class="friends-count">{{users.count}} пользователей</div>
                    <user-dropdown-filter :defaultSelected="orderBy" @onChange="onOrderByChange" :default-selected="(preloadFilterData) ? preloadFilterData.orderBy : null" :has-event-bus-emit="true"></user-dropdown-filter>
                </div>
                <div class="users-list-wrapper__row">
                    <DynamicScroller :items="users.list" :min-item-size="200" class="scroller" page-mode @update="handleScroll" :emitUpdate="true" :buffer="1000">
                        <template v-slot="{ item, index, active }">
                            <DynamicScrollerItem :item="item" :active="active" :data-index="item.id" :size-dependencies="[item]">
                                <User :user="item" :avatar="item.avatar_parsed" :send-request="true" :delete-friend="false" :delete-request="false" :accept-request="false" :save-prev-route="true"></User>
                            </DynamicScrollerItem>
                        </template>
                    </DynamicScroller>
                </div>
            </div>
	        <custom-modal ref="custom-modal" :has-cancel-btn="false"></custom-modal>
        </div>
    </keep-alive>
</template>

<script>
    import searchFilter from '../../../components/_shared/SearchFilter';
    import userComplexFilter from "../../../components/_shared/UserComplexFilter";
    import {usersService} from "../../../_api";
    import {handleAWSImage} from "../../../_helpers/aws-handler";
    import userDropdownFilter from "../../../components/_shared/UserFilterDropdown";
    import avatar from "../../../components/_shared/Avatar";
    import PersonProgress from "../../../components/_shared/UserProgress";
    import User from "../../../components/users/User";
    import CustomModal from "@/components/_shared/modals/_CustomModal";

    export default {
        name: 'search-people',
        data () {
            return {
                loadingChat: false,
                filteredUsers: [],
                loadIndexThreshold: 10,
                loadIndex: 10,
                page: 0,
                perPage: 10,
                // search_str: '',
                orderBy: 1,
                users: {
                    loading: false,
                    count: 0,
                    list: []
                },
                filterOpen: true,
                preloadFilterData: null,
                customMatchingOption: null
            }
        },
        created() {
            if (this.$route.query.preLoad) {
                let data = JSON.parse(localStorage.getItem('filterData'));
                data['page'] = 1;
                this.orderBy = data.orderBy;
                this.preloadFilterData = data;
                this.submitFilter(data);
                this.$router.replace({});
            }
            if (this.$route.query.matching_option) {
                this.customMatchingOption = parseInt(this.$route.query.matching_option);
                this.$router.replace({});
            }
            if (this.$route.query.close_filter) {
                this.filterOpen = false;
                this.$router.replace({});
            }
        },
        mounted() {},
        computed: {
            preLoad() {
                return this.$route.query.preLoad
            }
        },
        watch: {},
        methods: {
            onOrderByChange(data, reload) {
                this.orderBy = data;
                if (reload && this.users.list.length) {
                    this.$refs.filter.submitFilter();
                }

            },
            filterSubmitListener(data) {
                console.log(data);
                this.submitFilter(data);
            },
            /*filteredList (data) {
                this.search_str = data.searchStr;
                this.$refs.filter.submitFilter();
            },*/
            handleScroll(startIndex, endIndex) {
                if (endIndex === this.loadIndex) {
                    this.loadIndex += this.loadIndexThreshold;
                    this.$refs.filter.submitFilter('scroll');
                }
            },

            submitFilter(data) {
                console.log(data);
                console.log(this.preLoad);
                // console.log(this.search_str);
                this.users.loading = true;
                if (data.type === null) {
                    this.page = 0;
                    this.users.list = [];
                    this.loadIndex = this.loadIndexThreshold;
                }
                let objToSend = {
                    age_from: data.age_from,
                    age_to: data.age_to,
                    sex: (data.sex === 'any') ? null : data.sex,
                    goals: data.goals,
                    occupations: data.occupations,
                    orientation: data.orientation_status.map(o => {return o.id}).toString(),
                    family_status: data.family_status.map(o => {return o.id}).toString(),
                    children: (data.children) ? data.children.id : null,
                    education: (data.education) ? data.education.id : null,
                    work_status: (data.work_status) ? data.work_status.id : null,
                    location: data.location.place_id,
                    answers: data.selected_questions.map(o => {return o.selected_filter.id}).toString(),
                    perPage : (this.preLoad !== 'true') ? this.perPage : data.perPage,
                    page : (this.preLoad !== 'true') ? ((!this.page) ? 1 : this.page + 1) : data.page,
                    // name: (this.preLoad !== 'true') ? this.search_str : data.name,
                    name: data.name,
                    orderBy: (this.preLoad !== 'true') ? this.orderBy : data.orderBy // 1-BY_MATCHING; 2-BY_REG_DATE;
                };
                console.log(objToSend);
                usersService.getDetailedSearchAPI(objToSend).then((res) => {
                    // console.log(res);
                    if (res) {
                        this.users.count = res.count;
                        res.users.forEach((obj) => {
                            obj['avatar_parsed'] = null;
                            this.users.list.push(obj);
                            handleAWSImage(obj.avatar_135).then((res) => {
                                obj['avatar_parsed'] = res;
                            });
                        });
                        let objToSave = data;
                        objToSave['perPage'] = this.perPage;
                        objToSave['page'] = 1;
						console.log(this.page);
                        // objToSave['name'] = this.search_str;
                        objToSave['orderBy'] = this.orderBy;
                        localStorage.setItem('filterData', JSON.stringify(objToSave));
                        

                        if (res.users.length) {
                            this.page += 1;
	                        if (this.page === 1) {
		                        setTimeout(() => {
									let el = document.getElementById('with-similar-answers-anchor');
									if (data.selected_questions.length) {
										el.scrollIntoView({behavior: 'smooth'});
									} else {
										this.$refs.search.scrollIntoView({behavior: 'smooth'});
									}
		                        }, 500);
	                        }
                        } else if (!res.users.length && this.page === 0) {
	                        this.$refs['custom-modal'].show({
		                        message: 'По данному запросу людей нет',
		                        okButton: 'Ok',
	                        }).then((result) => {
		                        console.log(result);
		                        if (result) {
			
		                        }
	                        });
                        }
                    }
                    setTimeout(() => {
                        this.users.loading = false;
                    }, 500)
                })
            }
        },
        components: {
	        CustomModal,
            User,
            PersonProgress,
            userDropdownFilter,
            userComplexFilter,
            searchFilter,
            avatar
        }
    }
</script>

<style lang="less" scoped>
    .scroller {
        height: 100%;
    }
    #search-people {
        .fadeFilter-enter-active, .fadeFilter-leave-active {
            transition: all .3s;
        }
        .fadeFilter-enter, .fadeFilter-leave-to /* .fade-leave-active below version 2.1.8 */ {
            opacity: 0;
        }
        .search-people-content {
            width: 100%;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            border-radius: 1px;
            margin-bottom: 34px;
            background: #fff;
            padding-top: 20px;
            .search-filter {
                border-top: 1px solid #e3e6eb;
                border-bottom: 1px solid #e3e6eb;
                margin-bottom: 16px;
            }
            .top-bar {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 32px;
                padding: 0 16px;
                .friends-count {
                    font-size: 14px;
                    line-height: 14px;
                    color: #666666;
                }
                .sort-wrapper {
                    font-size: 12px;
                    line-height: 14px;
                    color: #333333;
                }
            }
            .users-list-wrapper__row {
                /*width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;*/
                .user-item {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding: 8px 25px;
                    border-bottom: 1px solid #e3e6eb;
                    .avatar-wrapper {
                        margin-right: 25px;
                        cursor: pointer;
                    }
                    .user-info-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        width: 100%;
                        .main-info {
                            width: 68%;
                            text-align: left;
                            border-right: 1px solid #e3e6eb;
                            margin-bottom: 9px;
                            .user-fullname {
                                cursor: pointer;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                color: #666666;
                                margin-bottom: 8px;
                            }
                            .user-description {
                                font-size: 14px;
                                line-height: 16px;
                                color: #999999;
                                margin-bottom: 8px;
                            }
                            .person-progress {
                                margin-bottom: 8px;
                            }
                            .answers-wrapper {
                                span {
                                    font-size: 14px;
                                    line-height: 16px;
                                    color: #71809C;
                                    &.green-text-quantity {
                                        color: #AFD4AB;
                                        font-weight: bold;
                                    }
                                    &.red-text-quantity {
                                        color: #EDA2A4;
                                        font-weight: bold;
                                    }
                                    &.gray-text-quantity {
                                        color: #9B9B9B;
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                        .common-preferences {
                            width: 25%;
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 9px;
                            text-align: left;
                            justify-content: center;
                            span {
                                font-size: 13px;
                                line-height: 18px;
                                color: #333333;
                                margin-bottom: 14px;
                                &:last-child {
                                    margin-bottom: 0px;
                                }
                            }
                        }
                        .actions-wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            /*justify-content: space-between;*/
                            width: 100%;
                            .def-btn {
                                &:not(:last-child) {
                                    margin-right: 10px;
                                }
                                font-weight: 500;
                                font-size: 10px;
                                line-height: 12px;
                                margin-bottom: 8px;
                                width: 100%;
                                max-width: 150px;
                                &.disabled {
                                    background: rgba(0, 0, 0, 0.1)!important;
                                    color: #A9BAD4!important;
                                    cursor: not-allowed;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>

