<template>
    <div id="social-networks">
        <user-complex-filter header-title="Друзья из соцсетей" :close-after-submit="false" :show-filter="false" @filterSubmit="filterSubmitListener" :show-matched-option="false" :on-init-load="false" ref="filter" :custom-margin="'10'" :search-disabled="users.loading"></user-complex-filter>
        <div class="social-networks-content">
            <search-filter :show-error="true" :placeholderText="'Введите имя друга'" :items="users.list" @filtered="filteredList" class="search-filter" :is-server-side="true" @onChange="onSearchChange" :search-disabled="users.loading"></search-filter>
            <div class="top-bar">
                <div class="friends-count">{{users.count}} друзей</div>
                <user-dropdown-filter :defaultSelected="orderBy" @onChange="onOrderByChange"></user-dropdown-filter>
            </div>
            <div class="users-list-wrapper__row">
                <div class="user-item" v-for="item in users.list" :key="item.id">
                    <div class="avatar-wrapper" @click="goToProfile(item)">
                        <avatar :width="111" :height="111" :img="item.avatar_parsed"></avatar>
                    </div>
                    <div class="user-info-wrapper">
                        <div class="main-info">
                            <div class="user-fullname" @click="goToProfile(item)">
                                {{item.name}}
                            </div>
                            <person-progress width="165" height="4" :green="item.similar_answers_percent" :red="item.diff_answers_percent" :gray="item.not_answered_percent"></person-progress>

                            <div class="interests" v-if="item.interests && item.interests.length">
                                <span v-for="i in interestsComp(item)">{{i.name}}</span>
                                <span v-if="item.interests.length > 3" class="more">+{{item.interests.length - 3}}</span>
                            </div>

                        </div>
                        <div class="actions-wrapper">
                            <button class="def-btn" :class="{'def-btn': item.is_friend, 'def-btn disabled': !item.is_friend}" :disabled="!item.is_friend" @click="askQuestion(item)">Задать вопрос</button>
<!--                            <button class="def-btn" @click="startChat(item)">Написать сообщение1</button>-->
	                        <StartChatBtn :data="item"></StartChatBtn>
                            <button :disabled="item.is_friend_request === 1" v-if="!item.is_friend" :class="{'def-btn': !item.is_friend_request, 'def-btn disabled': item.is_friend_request}" @click="sendFriendRequest(item)">
                                {{(item.is_friend_request) ? 'Запрос отправлен' : 'Добавить в Друзья Up&Up'}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import searchFilter from '../../../components/_shared/SearchFilter';
    import userComplexFilter from "../../../components/_shared/UserComplexFilter";
    import {usersService} from "../../../_api";
    import {handleAWSImage} from "../../../_helpers/aws-handler";
    import userDropdownFilter from "../../../components/_shared/UserFilterDropdown";
    import avatar from "../../../components/_shared/Avatar";
    import PersonProgress from "../../../components/_shared/UserProgress";
    import StartChatBtn from "@/components/_shared/StartChatBtn";

    export default {
        name: 'social-networks',
        data () {
            return {
                loadingChat: false,
                filteredUsers: [],
                loadIndexThreshold: 20,
                loadIndex: 20,
                page: 1,
                perPage: 1000,
                search_str: '',
                orderBy: 1,
                users: {
                    loading: false,
                    count: 0,
                    list: [],
                    list_filtered: []
                },
                inviteLink: null
            }
        },
        created() {},
        mounted() {
            this.$refs.filter.submitFilter();
        },
        computed: {

        },
        watch: {},
        methods: {
            interestsComp(q) {
                console.log(q);
                return q.interests.filter((o, index) => {
                    return index <= 2
                })
            },
            goToProfile(data) {
                this.$router.push({path: `/profile/${data.id}`});
            },
            onOrderByChange(data) {
                this.orderBy = data;
                this.$refs.filter.submitFilter();
            },
            onSearchChange(data) {
                this.search_str = data;
            },
            filterSubmitListener(data) {
                this.submitFilter(data);
            },
            filteredList (data) {
                this.users.list_filtered = data.arr;
                this.search_str = data.searchStr;
                this.$refs.filter.submitFilter();
            },
            sendFriendRequest(data) {
                usersService.sendFriendRequestAPI({
                    id: data.id
                }).then((res) => {
                    if (res) {
                        data['is_friend_request'] = 1;
                        this.$forceUpdate();
                    }
                })
            },
            handleScroll(startIndex, endIndex, tab) {
                if (endIndex === this.loadIndex) {
                    this.users.loadIndex += this.users.loadIndexThreshold;
                }
            },
            startChat(data) {
                this.loadingChat = true;
                usersService.getChatIdAPI(data).then((res) => {
                    this.loadingChat = false;
                    if (res) {
                        // console.log(res);
                        this.$router.push({ path: '/messages', query: { t: res, ff: true } });
                    }
                })
            },
            submitFilter(data) {
                this.users.loading = true;
                let objToSend = {
                    age_from: data.age_from,
                    age_to: data.age_to,
                    sex: (data.sex === 'any') ? null : data.sex,
                    goals: data.goals,
                    occupations: data.occupations,
                    orientation: data.orientation_status.map(o => {return o.id}).toString(),
                    family_status: data.family_status.map(o => {return o.id}).toString(),
                    children: (data.children) ? data.children.id : null,
                    education: (data.education) ? data.education.id : null,
                    work_status: (data.work_status) ? data.work_status.id : null,
                    location: data.location.place_id,
                    perPage : this.perPage,
                    page : this.page,
                    name: this.search_str,
                    orderBy: this.orderBy //1-BY_MATCHING; 2-BY_REG_DATE
                };
                // console.log(objToSend);
                this.users.list = [];
                usersService.getDetailedSearchSocialsAPI(objToSend).then((res) => {
                    // console.log(res);
                    if (res) {
                        this.users.count = res.count;
                        this.users.list = res.users;
                        this.users.list.forEach((obj) => {
                            if (obj.avatar) {
                                handleAWSImage(obj.avatar_64).then((res) => {
                                    obj['avatar_parsed'] = res;
                                    this.$forceUpdate();
                                });
                            } else {
                                obj['avatar_parsed'] = null;
                            }
                        });
                        /*this.friendshipRequests.page += 1;*/
                    }

                    setTimeout(() => {
                        this.users.loading = false;
                    }, 500);
                })
            },

            getDynamicInviteLink() {
                usersService.getInviteLinkAPI().then(res => {
                    // console.log(res);
                    if (res) {
                        this.inviteLink = res.result;
                    }
                })
            },
            inviteUser(type) {
                if (type === 'email') {
                    var email = '';
                    var subject = 'You\'re invited to UpAndUp system.';
                    var emailBody = 'Follow on the link to continue: %0D%0A' + this.inviteLink;
                    document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
                } else if (type === 'fb') {
                    FB.ui({
                        method: 'share',
                        href: this.inviteLink,
                        display: 'popup',
                    }, function(response){
                        // console.log(response);
                    });
                } else if (type === 'vk') {
                    this.$refs.vk_link.click();
                }
            },
            showInviteFriendsModal() {
                this.$refs['invite-users-modal'].show();
                this.getDynamicInviteLink();
            },
            resetModal() {
                this.inviteLink = null;
            },
            askQuestion(item) {
                this.$router.push({path: '/questions/personal-questions',  query: { user_id: item.id }});
            },
        },
        components: {
	        StartChatBtn,
            PersonProgress,
            userDropdownFilter,
            userComplexFilter,
            searchFilter,
            avatar
        }
    }
</script>

<style lang="less" scoped>
    #social-networks {
        .social-networks-content {
            width: 100%;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            border-radius: 1px;
            margin-bottom: 34px;
            background: #fff;
            .search-filter {
                border-top: 1px solid #e3e6eb;
                border-bottom: 1px solid #e3e6eb;
                margin-bottom: 16px;
            }
            .top-bar {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 32px;
                padding: 0 16px;
                .friends-count {
                    font-size: 14px;
                    line-height: 14px;
                    color: #666666;
                }
                .sort-wrapper {
                    font-size: 12px;
                    line-height: 14px;
                    color: #333333;
                }
            }
            .users-list-wrapper__row {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                .user-item {
                    width: 33.3%;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: center;
                    padding: 8px 25px;
                    .avatar-wrapper {
                        margin-bottom: 20px;
                        cursor: pointer;
                    }
                    .user-info-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        width: 100%;
                        .main-info {
                            width: 100%;
                            text-align: left;
                            // border-right: 1px solid #e3e6eb;
                            margin-bottom: 9px;
                            .user-fullname {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                color: #666666;
                                margin-bottom: 8px;
                                text-align: center;
                                word-break: break-all;
                                cursor: pointer;
                            }
                            .user-description {
                                font-size: 14px;
                                line-height: 16px;
                                color: #999999;
                                margin-bottom: 8px;
                            }
                            .person-progress {
                                margin: 0 auto 8px auto;
                            }
                            .answers-wrapper {
                                span {
                                    font-size: 14px;
                                    line-height: 16px;
                                    color: #71809C;
                                    &.green-text-quantity {
                                        color: #AFD4AB;
                                        font-weight: bold;
                                    }
                                    &.red-text-quantity {
                                        color: #EDA2A4;
                                        font-weight: bold;
                                    }
                                    &.gray-text-quantity {
                                        color: #9B9B9B;
                                        font-weight: bold;
                                    }
                                }
                            }
                            .interests {
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                align-items: center;
                                span {
                                    font-size: 13px;
                                    color: #9B9B9B;
                                    border: 1px solid #CBCBCB;
                                    padding: 1px 3px;
                                    margin-right: 2px;
                                    margin-bottom: 2px;
                                }
                            }
                        }
                        .common-preferences {
                            width: 25%;
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 9px;
                            text-align: left;
                            justify-content: center;
                            span {
                                font-size: 13px;
                                line-height: 18px;
                                color: #333333;
                                margin-bottom: 14px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                        .actions-wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: wrap;
                            /*justify-content: space-between;*/
                            width: 100%;
                            .def-btn, #start-chat-btn  {
                                /*&:not(:last-child) {
                                    margin-right: 10px;
                                }*/
                                font-weight: 500;
                                font-size: 10px;
                                line-height: 12px;
                                margin-bottom: 8px;
                                width: 100%;
                                max-width: 150px;
                                &.disabled {
                                    background: rgba(0, 0, 0, 0.1)!important;
                                    color: #A9BAD4!important;
                                    cursor: not-allowed;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {
        #social-networks {
            .social-networks-content {
                .users-list-wrapper__row {
                    .user-item {
                        width: 100%;
                    }
                }
            }
        }
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {

    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {

    }
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {

    }

</style>

