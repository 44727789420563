<template>
    <div id="inner-circle-wrapper">
        <user-complex-filter v-if="!isModal" header-title="Друзья Up&Up" :close-after-submit="false" :show-filter="false" :show-matched-option="false" :on-init-load="false" ref="filter" :custom-margin="'10'" :only-header="true" :search-disabled="filterLoading"></user-complex-filter>
        <ul class="nav nav-tabs mb-3" v-if="!isModal">
            <li
                    v-for="tab in tabs"
                    :key="tab.name"
                    :class="['nav-item', { active: currentTab.name === tab.name }]"
                    @click="changeTab(tab)">
                <a class="nav-link" :class="[{ active: currentTab.name === tab.name }]">
                    <span>{{ tab.name }}</span>
                </a>
            </li>
        </ul>
        <div class="page-content">
            <component :is="currentTab.component"></component>
        </div>
    </div>
</template>

<script>
    import friendshipRequests from "../../../components/users/inner-circle/InnerCircleRequests";
    import friendsList from "../../../components/users/inner-circle/InnerCircleList";
    import userComplexFilter from "../../../components/_shared/UserComplexFilter";

    export default {
        name: 'main-questions',
        props: ['isModal'],
        components: {
            friendshipRequests,
            friendsList,
            userComplexFilter
        },
        data () {
            return {
                filterLoading: false,
                tabs: [
                    {
                        name: 'Друзья Up&Up',
                        id: 1,
                        activeComp: 'friends',
                        component: friendsList
                    }, {
                        name: 'Запросы',
                        id: 2,
                        activeComp: 'friendship_requests',
                        component: friendshipRequests
                    }
                ],
                currentTab: null
            }
        },
        created() {
            (this.$route.query.tab !== undefined) ? this.changeTab(this.tabs[this.$route.query.tab]) : this.changeTab(this.tabs[0]);
        },
        mounted () {},
        beforeDestroy() {},
        computed: {},
        methods: {
            changeTab(tab) {
              console.log('tab', tab)
                this.currentTab = tab;
            },
            askQuestion(item) {
                this.$router.push({path: '/questions/personal-questions', query: {user_id: item.id}});
            }
        },
        watch: {
            isChangedTab: function (res) {
                this.changeTab(this.tabs[res]);
            },
        }
    }
</script>

<style lang="less" scoped>
    #inner-circle-wrapper {}
</style>

